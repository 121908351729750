import React from "react"
import { Player, ControlBar, BigPlayButton, LoadingSpinner } from "video-react"
import { Image, Transformation } from "cloudinary-react"

const ProjectSection = props => {
  ProjectSection.props = {
    id: props.id.isRequired,
    typeHandle: props.typeHandle.isRequired,
    backgroundColour: props.backgroundColour.isRequired,
    backgroundImage: props.backgroundImage,
    images: props.images,
    heading: props.heading,
    leftColumn: props.leftColumn,
    rightColumn: props.rightColumn,
    padding: props.padding,
  }

  let projectBackground

  if (props.backgroundImage) {
    projectBackground = {
      backgroundImage: `url(${props.backgroundImage})`,
    }
  } else {
    projectBackground = {}
  }

  if (props.typeHandle === "projectSection") {
    return (
      <section
        key={props.id}
        className={
          "o-section o-section--project " +
          (props.backgroundColour === "grey" ||
          props.backgroundColour === "black"
            ? "o-section--no-pad-bottom"
            : "") +
          " u-bg-" +
          props.backgroundColour +
          " " +
          (props.padding === "noPaddingTop" ? "o-section--no-pad-top" : "") +
          (props.padding === "noPaddingBottom"
            ? "o-section--no-pad-bottom"
            : "")
        }
        style={projectBackground}
      >
        <div className="u-wrapper u-columns u-columns--8">
          {props.heading}
          {props.images.map(image => (
            <div key={image.id} className={"u-columns-" + image.columnWidth}>
              {image.kind === "image" && image.cloudinaryPublicId !== null ? (
                <Image
                  cloudName="warehousesix"
                  publicId={image.cloudinaryPublicId}
                  dpr="auto"
                  responsive
                  width="auto"
                  className={"o-image o-image--" + image.imageType}
                >
                  <Transformation quality="auto" fetchFormat="auto" />
                </Image>
              ) : (
                <Player muted autoPlay playsInline preload="auto">
                  <source src={image.url} />
                  <ControlBar disableCompletely={true} />
                  <BigPlayButton position="center" />
                  <LoadingSpinner />
                </Player>
              )}
            </div>
          ))}
        </div>
      </section>
    )
  }

  if (props.typeHandle === "projectSectionColumns") {
    return (
      <section
        key={props.id}
        className={
          "o-section o-section--project " +
          (props.backgroundColour === "grey" ||
          props.backgroundColour === "black"
            ? "o-section--no-pad-bottom"
            : "") +
          " u-bg-" +
          props.backgroundColour
        }
      >
        <div className="u-wrapper u-columns u-columns--2">
          <div className="u-column">
            {props.leftColumn.map(image => (
              <div key={image.id} className="u-column__item">
                {image.kind === "image" ? (
                  <Image
                    cloudName="warehousesix"
                    publicId={image.cloudinaryPublicId}
                    dpr="auto"
                    responsive
                    width="auto"
                    className={image.imageType}
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                  </Image>
                ) : (
                  <Player muted autoPlay playsInline preload="auto">
                    <source src={image.url} />
                    <ControlBar disableCompletely={true} />
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                  </Player>
                )}
              </div>
            ))}
          </div>
          <div className="u-column">
            {props.rightColumn.map(image => (
              <div key={image.id} className="u-column__item">
                {image.kind === "image" ? (
                  <Image
                    cloudName="warehousesix"
                    publicId={image.cloudinaryPublicId}
                    dpr="auto"
                    responsive
                    width="auto"
                    className={image.imageType}
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                  </Image>
                ) : (
                  <Player muted autoPlay playsInline preload="auto">
                    <source src={image.url} />
                    <ControlBar disableCompletely={true} />
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                  </Player>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

export default ProjectSection
