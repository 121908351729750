import { Link } from "gatsby"
import React from "react"
import Arrow from "../components/arrow"

const Paginate = (props) => {

    return (
        <div className="c-paginate">
            {props.prevProjectLink &&
                <div className="c-paginate__link c-paginate__prev">
                    <Link to={props.prevProjectLink}><Arrow /> Previous <span>Project</span></Link>
                </div>
            }
            {props.nextProjectLink &&
                <div className="c-paginate__link c-paginate__next">
                    <Link to={props.nextProjectLink}>Next <span>Project</span> <Arrow /></Link>
                </div>
            }
        </div>
    )
    
  }
  
  export default Paginate
  