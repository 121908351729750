import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../layouts/default"
import Paginate from "../components/paginate"
import ProjectSection from "../components/project-section"

const projectDetail = ({ data }) => {
  const project = data.craft.entry
  const thumbnail = project.thumbnail.map(image => image.url)
  let prevProject = data.craft.prevProject[0]
  let nextProject = data.craft.nextProject[0]

  if (typeof prevProject === "undefined") {
    prevProject = false
  }
  if (typeof nextProject === "undefined") {
    nextProject = false
  }

  return (
    <Layout>
      <SEO
        title={project.title}
        description={project.longTitle}
        thumbnail={thumbnail}
      />

      <section className="o-section u-bg-white">
        <div className="u-wrapper u-columns u-columns--2">
          <h1>{project.longTitle || project.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: project.intro }} />
        </div>
      </section>

      {project.projectSection.map(item => (
        <ProjectSection
          key={item.id}
          id={item.id}
          typeHandle={item.typeHandle}
          backgroundColour={item.backgroundColour}
          backgroundImage={item.backgroundImage}
          heading={item.heading}
          images={item.images}
          leftColumn={item.leftColumn}
          rightColumn={item.rightColumn}
          padding={item.padding}
        ></ProjectSection>
      ))}

      <Paginate
        prevProjectLink={prevProject.slug}
        nextProjectLink={nextProject.slug}
      ></Paginate>
    </Layout>
  )
}

export const query = graphql`
  query Entry($slug: [String], $id: Int) {
    craft {
      entry(slug: $slug) {
        ... on Craft_projects_projects_Entry {
          title
          longTitle
          intro
          thumbnail {
            id
            url
            title
            ... on Craft_images_Asset {
              cloudinaryPublicId
            }
          }
          projectSection {
            ... on Craft_projectSection_projectSection_BlockType {
              id
              typeHandle
              heading
              backgroundColour
              backgroundImage
              padding
              images {
                id
                url
                kind
                title
                ... on Craft_images_Asset {
                  columnWidth
                  imageType
                  cloudinaryPublicId
                }
              }
            }
            ... on Craft_projectSection_projectSectionColumns_BlockType {
              id
              typeHandle
              backgroundColour
              leftColumn {
                id
                url
                kind
                title
                ... on Craft_images_Asset {
                  imageType
                  cloudinaryPublicId
                }
              }
              rightColumn {
                id
                url
                kind
                title
                ... on Craft_images_Asset {
                  imageType
                  cloudinaryPublicId
                }
              }
            }
          }
        }
      }
      prevProject: entries(section: "projects", prevSiblingOf: $id) {
        ... on Craft_projects_projects_Entry {
          id
          url
          slug
        }
      }
      nextProject: entries(section: "projects", nextSiblingOf: $id) {
        ... on Craft_projects_projects_Entry {
          id
          url
          slug
        }
      }
    }
  }
`

export default projectDetail
